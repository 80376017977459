import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { ModalContext } from '../contexts'

type UploadOptionTypes = {
    //최대 갯수
    maxFileCount: number
    //총 업로드 사이즈 MB
    // maxUploadFileSize: number
    defaultFiles?: any[]
}
const useFileUploader = ({
    maxFileCount,
    // maxUploadFileSize,
    defaultFiles,
}: UploadOptionTypes): [File[], ReactNode, ReactNode] => {
    //파일 당 최대 업로드 사이즈 : 20MB
    const maxFileUploadSize = 20
    const modalContext = useContext(ModalContext)
    const [files, setFiles] = useState<File[]>([])
    const [uploadSize, setUploadSize] = useState<number>(0)
    // let size = 0
    useEffect(() => {
        //초기 랜더링 default file set
        setFiles([])
        defaultFiles?.forEach((defaultFile) => {
            fetch(defaultFile.url)
                .then((response) => {
                    return response.blob()
                })
                .then((blob) => {
                    const file = new File([blob], defaultFile.origin_name, { type: defaultFile.mime_type })
                    setFiles((files) => [...files, file])
                    setUploadSize(uploadSize + file.size)
                })
                .catch((error) => {
                    alert(defaultFile.origin_name + '파일 로드 중 오류가 발생했습니다.')
                    console.log(error)
                })
        })
    }, [defaultFiles])

    const allowedMimeTypes = [
        'image/jpeg',
        'image/png',
        'application/zip',
        'application/x-zip',
        'application/x-zip-compressed',
        'application/x-compressed',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
    ]
    const allowedExtension = ['jpg', 'jpeg', 'png', 'xls', 'xlsx', 'pdf', 'zip', 'txt']
    const previewAllowdMimeTypes = ['image/jpeg', 'image/png', 'application/pdf']

    const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let filesCount = files.length
            // let uploadFileSize = uploadSize

            const errorMimeTypeFiles: File[] = []
            const errorExtentionFiles: File[] = []
            const errorSizeFiles: File[] = []
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i]

                const fileNameSplit = file.name.split('.')
                const extension = fileNameSplit[fileNameSplit.length - 1]

                if (!allowedExtension.includes(extension)) {
                    errorExtentionFiles.push(file)
                    continue
                }
                if (!allowedMimeTypes.includes(file.type)) {
                    errorMimeTypeFiles.push(file)
                    continue
                }
                if (filesCount >= maxFileCount) {
                    alert(`최대  ${maxFileCount} 개 업로드 가능 합니다.`)
                    break
                }
                //업로드 파일 사이즈 MB
                const fileSize = Math.ceil(file.size / 100000) / 10
                if (fileSize > maxFileUploadSize) {
                    errorSizeFiles.push(file)
                    break
                }

                if (uploadSize + file.size > 50 * 1000000) {
                    alert(
                        `최대 업로드 가능 크기를 초과 했습니다 \n 최대 업로드 사이즈 (${50} MB / ${
                            (uploadSize + file.size) / 1000000
                        } MB)`
                    )
                    break
                } else {
                    setUploadSize(uploadSize + file.size)
                }

                setFiles((prevFiles) => [...prevFiles, file])
                // setUploadSize(uploadSize + file.size)
            }

            if (errorSizeFiles.length) {
                alert(`파일당 최대 ${maxFileUploadSize}MB 업로드 가능합니다.`)
            }
            if (errorExtentionFiles.length) {
                alert(
                    `업로드 제한 확장자가 있습니다.\n${errorExtentionFiles
                        .map((file) => {
                            return file.name
                        })
                        .join('\n')}`
                )
            }
            if (errorMimeTypeFiles.length) {
                alert(
                    `잘못된 파일 타입이 있습니다.\n${errorMimeTypeFiles
                        .map((file) => {
                            return file.name
                        })
                        .join('\n')}`
                )
            }
        }
    }
    const removeFile = (index: number) => {
        const file = files[index]
        setUploadSize(uploadSize - file.size)
        setFiles(files.filter((item, fileIndex) => fileIndex != index))
    }
    const preview = (file: File) => {
        if (!previewAllowdMimeTypes.includes(file.type)) {
            alert('미리보기가 지원되지 않는 파일입니다.')
        } else {
            const reader = new FileReader()
            reader.onload = (e) => {
                const dataURL = e.target?.result
                const previewContent =
                    file.type == 'application/pdf' ? (
                        <iframe src={dataURL?.toString()} style={{ width: '100%', height: '80vh' }}></iframe>
                    ) : (
                        <img
                            src={dataURL?.toString()}
                            style={{ width: '100%', height: '80vh', objectFit: 'contain' }}
                        />
                    )
                modalContext.activeModalHandler(previewContent, file.name)
            }
            reader.readAsDataURL(file)
        }
    }
    const download = (file: File) => {
        const tempLink = document.createElement('a')
        tempLink.href = window.URL.createObjectURL(file)
        tempLink.download = file.name
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
    }
    const fileUploadContainer = (
        <>
            <div className='upload-list'>
                {files.length ? (
                    files.map((file, index) => {
                        return (
                            <div key={index}>
                                <span
                                    onClick={() => {
                                        preview(file)
                                    }}
                                >
                                    {file.name}
                                </span>
                                <div>
                                    <span
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()
                                            download(file)
                                        }}
                                    >
                                        저장
                                    </span>
                                    <span
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()
                                            removeFile(index)
                                        }}
                                    >
                                        {/* &#x2715; */}
                                        삭제
                                    </span>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <span>업로드 가능 확장자 : {allowedExtension.join(', ')} </span>
                )}
            </div>
            <label className='file-label'>
                파일선택
                <input
                    type='file'
                    onChange={uploadFile}
                    style={{ display: 'none' }}
                    accept={allowedMimeTypes.join(',')}
                />
            </label>
        </>
    )
    const resultContainer = (
        <div className='upload-list result'>
            {files?.map((file, index) => {
                return (
                    <div key={index}>
                        <span>{file ? file.name : '로딩중...'}</span>
                        <div>
                            {file && (
                                <span
                                    style={{ marginLeft: 5, cursor: 'pointer' }}
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation()
                                        download(file)
                                    }}
                                >
                                    저장
                                </span>
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )

    return [files, fileUploadContainer, resultContainer]
}

export default useFileUploader
