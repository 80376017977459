import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../contexts'

const Footer = () => {
    const globalContext = useContext(GlobalContext)
    const { config } = globalContext.globalValue
    return (
        <footer>
            <div className='images'>
                <img src='/images/footer_desktop.jpeg' className='desktop' />
                <img src='/images/footer_mobile.jpeg' className='mobile' />
            </div>

            <div className='banner'>
                <ul>
                    <li>
                        <a href='http://www.infra-k.net/' target='_blank'>
                            <img src='/images/banners/infrak.png' alt='banner' />
                        </a>
                    </li>
                    <li>
                        <a href='/koreaInfo.html' target='_blank'>
                            <img src='/images/banners/koreaInfo.png' alt='banner' />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.daraeparktech.com/' target='_blank'>
                            <img src='/images/banners/daraeparktech.png' alt='banner' />
                        </a>
                    </li>
                    <li>
                        <a href='http://www.ist21.net/index.php' target='_blank'>
                            <img src='/images/banners/ist21.png' alt='banner' />
                        </a>
                    </li>

                    <li>
                        <a href='https://kr.canon/main' target='_blank'>
                            <img src='/images/banners/canon.png' alt='banner' />
                        </a>
                    </li>
                </ul>
            </div>
            <div className='company'>
                <ul>
                    <li>
                        <Link to='/sub/privacy'>개인정보처리방침</Link>
                    </li>
                    <li>
                        <Link to='/sub/terms'>홈페이지이용약관</Link>
                    </li>
                    <li>
                        <Link to='/sub/refund'>환불정책안내</Link>
                    </li>
                </ul>
            </div>
            <div className='license'>
                <div>
                    {/* <img src={logoImage} /> */}
                    <img src='/images/banners/infrak_black.jpeg' />
                </div>
                <div>
                    <ul>
                        <li>통신판매업신고 제2024-제주외도-0037호</li>
                        <li>사업자등록번호 552-88-00867</li>
                    </ul>
                    <ul>
                        <li>회사 주식회사 인프라케이</li>
                        <li>대표이사 선무홍</li>
                        <li>주소 제주특별자치도 제주시 우령6길 16-11, 1층 101호</li>
                    </ul>
                    <ul>
                        <li>
                            전화 <a href={`tel:${config.contact}`}>{config.contact}(고객센터)</a>
                        </li>
                        <li>팩스 064-724-1042</li>
                        <li>
                            이메일 <a href={`mailto:${config.email}`}>{config.email}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='copy'>Copyright &copy; 비투비제주 All rights reserved.</div>
        </footer>
    )
}

export default Footer
