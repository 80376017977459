import React, { FC, Fragment, useContext, useEffect, useState } from 'react'
import { ModalContext } from '../contexts'
import { useCookies } from 'react-cookie'
import { Navigation, Pagination } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const PopUpLayer: FC = () => {
    const { activeModalHandler } = useContext(ModalContext)

    const [popupItems, setPopupList] = useState<any[]>([
        // { id: 1, src: '/images/popup_1.jpeg', title: '존경하는 도지사님께 드리는 호소문' },
        // { id: 2, src: '/images/popup_1.jpeg', title: '존경하는 도지사님께 드리는 호소문' },
    ])
    const [cookies, setCookie, removeCookie] = useCookies()
    useEffect(() => {
        if (
            !popupItems.filter((popupItem) => {
                return !cookies[`popup_${popupItem.id}`]
            }).length
        ) {
            activeModalHandler(null)
        }
    }, [popupItems])

    const closeHandler = (id: number, index: number, weekClose?: boolean) => {
        if (weekClose) {
            const date = new Date()
            date.setDate(date.getDate() + 7)
            setCookie(`popup_${id}`, 1, {
                expires: date,
            })
        }
        setPopupList(
            popupItems.filter((popupItem, popupItemindex) => {
                return popupItemindex != index
            })
        )
    }
    return (
        <div className='popup-wrapper'>
            <Swiper
                modules={[Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                loop
            >
                {popupItems
                    .filter((popupItem) => {
                        return !cookies[`popup_${popupItem.id}`]
                    })
                    .map((popupItem, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className='popup-item'>
                                    <div className='header'>{popupItem.title}</div>
                                    <img src={popupItem.src} />
                                    <div className='controls'>
                                        <button
                                            type='button'
                                            onClick={() => {
                                                closeHandler(popupItem.id, index, true)
                                            }}
                                        >
                                            일주일 동안 다시 열람하지 않습니다.
                                        </button>
                                        <button
                                            type='button'
                                            onClick={() => {
                                                closeHandler(popupItem.id, index)
                                            }}
                                        >
                                            닫기
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
            </Swiper>
        </div>
    )
}

export default PopUpLayer
